import React from 'react';
import ReactDOM from 'react-dom/client';

const elem = <button onClick={def}>Click Me</button>


async function abc() {

    const url= "https://b.codeify.co.in/backend/b";
    
    const data = {
        username: 'a',
        password: 'b'
      };

      const options = {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          'Auth': 'test'
        },
        body: JSON.stringify(data)
      };

      fetch(url, options)
        .then(response => response.json())
        .then(data => console.log(data))
        .catch(error => console.error(error));
    
    }

    async function def() {

      const url= "https://b.codeify.co.in/backend/c";
      
      const data = {
          username: 'a1',
          password: 'b1'
        };
  
        const options = {
          method: 'POST',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
            'Auth': 'test'
          },
          body: JSON.stringify(data)
        };
  
        fetch(url, options)
          .then(response => response.json())
          .then(data => console.log(data))
          .catch(error => console.error(error));
      
      }

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(elem);

await abc();